@media only screen and (min-width: 1200px) {
  .social-media-section {
    padding-top: 80px;

    .section-title {
      margin-bottom: 85px;
    }

    .instagram-highlights {
      max-width: 570px;
    }

    .instagram-card {
      width: 130px;
      height: 130px;
      margin: 5px;

      &.view-on-instagram {
        img {
          width: 40px;
          height: 40px;
          margin-bottom: 12px;
        }
        p {
          max-width: 70px;
          font-size: 14px;
        }
      }
    }
    .twitter-card {
      iframe {
        height: 100% !important;
      }
    }
  }
}
