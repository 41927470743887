@media only screen and (min-width: 1200px) {
  .main {
    .section {
      .section-title {
        font-size: 60px;
      }
    }
    .hero-section {
      padding-top: 90px;
      padding-bottom: 130px;

      .bg-angled {
        position: absolute;
        z-index: -2;
        background-color: $theme-link-water;
        width: 80%;
        height: 100%;
        right: 0;
        bottom: -100vw * tan(26deg);
        display: block;
        transform: skewY(-28deg);
        transform-origin: 0;
      }

      .section-title {
        margin-bottom: 55px;
        text-align: left;
      }
      .hero-content {
        margin-bottom: 50px;
        text-align: left;
      }
      .home-hero-video {
        height: 380px;

        img {
          margin-bottom: 0;
        }
      }
    }
    .actions {
      margin-top: -40px;
      text-align: left;

      .sign-up {
        background-color: $theme-link-water;
        padding-right: 25px;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-left: 100px;
        justify-content: flex-end;
        margin-right: 35px;
        display: inline-block;

        a {
          padding: 12px 15px;
        }
      }
      .know-more {
        padding: 12px 18px;
        height: 42px;
        display: inline-block;
      }
    }
    .why-join-section {
      padding-top: 100px;
      padding-bottom: 100px;

      .section-title {
        margin-bottom: 90px;
      }

      .reason {
        &:first-child {
          margin-bottom: 70px;
        }
        .reason-count {
          font-size: 100px;
        }
        .reason-title {
          font-size: 44px;
        }
      }
      .reason-expand {
        height: 385px;

        .reason-count-block {
          margin-top: -165px;

          .reason-count {
            font-size: 350px;
            margin-right: -40px;
          }
        }

        .reason-content {
          padding: 40px;
        }

        .reason-image {
          width: 100px;
          margin-bottom: 20px;
        }

        .reason-title {
          font-size: 44px;
          margin-bottom: 30px;
        }

        .reason-text {
          max-width: 230px;
        }
      }
    }

    .event-section {
      padding-top: 100px;
      padding-bottom: 65px;
      .slick-list {
        padding-left: 0 !important;
      }

      .section-title {
        margin-bottom: 100px;
      }
      .events-banner {
        max-width: 420px;
      }

      .event {
        width: 520px;
        margin-left: 0;

        .event-date {
          margin-bottom: 7px;
          font-size: 36px;
        }

        .event-time {
          font-size: 12px;
        }

        .event-title {
          font-size: 36px;
          margin-bottom: 16px;
        }

        .event-tags {
          margin-bottom: 16px;
        }
      }

      .event-actions {
        justify-content: flex-start;
        margin-top: 20px;
        margin-bottom: 0;
      }

      .slider-fade {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 200px;
        background-image: linear-gradient(to right, transparent, white);
      }
    }

    .subscribe-section {
      padding-top: 50px;
      padding-bottom: 50px;

      .section-title {
        margin-bottom: 50px;
        font-size: 44px;
      }

      .section-content {
        margin-bottom: 50px;
      }

      input[type='text'] {
        width: 500px;
        height: 60px;
        padding-left: 20px;
        padding-right: 120px;
        font-size: 16px;
      }

      button {
        margin-left: -110px;
        height: 40px;
        width: 100px;
        font-size: 16px;
      }
    }
  }
}
