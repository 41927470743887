@media only screen and (min-width: 768px) {
  .social-media-section {
    padding-top: 50px;

    .instagram-highlights {
      justify-content: center;
      max-width: 375px;
    }

    .instagram-card {
      width: 105px;
      height: 105px;
    }
    .twitter-card {
      iframe {
        height: 240px !important;
      }
    }
  }
}
