// styles for the new theme

@import "variables";
@import "fonts";
@import "trigonometry";
@import "./utilities/utilities";

.angled-top {
  position: relative;

  &::before {
    background: inherit;
    top: -100vw * tan(2.5deg);
    content: "";
    display: block;
    height: 70%;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(-3deg);
    transform-origin: 0;
  }
}

.colored-box {
  height: 15px;

  &.colored-box-dark {
    background-color: $theme-cadet-blue;
  }
  &.colored-box-light {
    background-color: $theme-athens-gray;
  }
}

.bg-gradient-blue {
  background-image: -webkit-linear-gradient(
    120deg,
    $theme-cello 35%,
    $theme-stone 65%
  );
  background-image: -o-linear-gradient(
    120deg,
    $theme-cello 35%,
    $theme-stone 65%
  );
  background-image: linear-gradient(120deg, $theme-cello 35%, $theme-stone 65%);

  &:hover {
    background-image: -webkit-linear-gradient(
      120deg,
      $hover-theme-cello 35%,
      $hover-theme-stone 65%
    );
    background-image: -o-linear-gradient(
      120deg,
      $hover-theme-cello 35%,
      $hover-theme-stone 65%
    );
    background-image: linear-gradient(
      120deg,
      $hover-theme-cello 35%,
      $hover-theme-stone 65%
    );
  }

  &.disabled:hover {
    background-image: -webkit-linear-gradient(
      120deg,
      $theme-cello 35%,
      $theme-stone 65%
    );
    background-image: -o-linear-gradient(
      120deg,
      $theme-cello 35%,
      $theme-stone 65%
    );
    background-image: linear-gradient(
      120deg,
      $theme-cello 35%,
      $theme-stone 65%
    );
  }
}

.btn-theme-primary {
  @extend .bg-gradient-blue;
  @extend .font-intel-clear-latin-bold;
  font-size: 16px;
  line-height: 1;
  padding: 15px;
  border-radius: 0;
  color: white;
  border: 0;
}

.login-signup-card .profile-picture {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
}

button,
input[type="submit"] {
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.btn-primary {
  background: #ff5663;
  border-color: #ff5663;
}

.btn-primary:hover,
.btn-crimson:hover {
  background: #feb6b9;
  border-color: #feb6b9;
}

.btn-crimson {
  background: $theme-crimson;
  border-color: $theme-crimson;
}

.blog-container {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15) !important;
}

.page-dashboard .blog-container {
  margin-bottom: 0 !important;
}

.faq-list-hover:hover h5 {
  color: #162b4d !important;
}
.faq-list-hover:hover h5::after {
  color: #162b4d !important;
}

.faq-list-hover div:not(.collapsed) {
  padding-bottom: 20px !important;
  color: #162b4d;
  border-bottom: 1px solid #162b4d !important;
}

.faq-list-hover div:not(.collapsed) > h5 {
  color: #162b4d !important;
}

.faq-list-hover :not(.collapsed) .plus-icon::after {
  font-family: "Ionicons";
  content: "\002D";
}

.border-hover:hover + div {
  border-top: 3px solid #162b4d !important;
}

.plus-icon::after {
  font-family: "Ionicons";
  content: "\002B";
  position: absolute;
  float: right;
  right: 1rem;
  font-size: 18px;
  color: #7288a2;
  padding: 5px;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #7288a2;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.w-30 {
  width: 30% !important;
}

.AI-card {
  border-radius: 10px;
  margin-bottom: 20px;
  height: 92%;
  span {
    font-size: 9px;
    margin-right: 5px;
  }
  p {
    line-height: 1.5;
    font-size: 13px;
    font-family: system-ui;
  }
}

.AI-image {
  max-width: 200px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 10px;
}

.student-data {
  color: #7288a2;
  font-size: 14px;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 3px;
}

.view-icon path {
  stroke: white;
}

.select-role:hover {
  background-color: $theme-athens-gray;
}

.fz-12 {
  font-size: 12px;
}

.fz-16 {
  font-size: 16px;
}

.pt-240 {
  padding-top: 240px;
}

.latest-events,
.latest-ai,
.latest-blogs {
  .slick-track {
    display: flex !important;
    align-items: stretch !important;
    .slick-slide {
      height: auto !important;
      > div {
        height: 100%;
        .card-dashboard {
          height: calc(100% - 12px);
        }
      }
    }
  }
}

.ws-pw {
  white-space: pre-wrap;
}

.left-50 {
  left: 50% !important;
}

.tranform-translate-x-n50 {
  transform: translateX(-50%) !important;
}

@media (min-width: 768px) {
  .pt-md-200 {
    padding-top: 200px;
  }
}

@import "./media/md";
@import "./media/xl";
@import "./media/hd";
@import "./social-media-section/social-media-section";
@import "./header/header";
@import "./home/home";
@import "./footer/footer";
@import "./authscreens/authscreens";
@import "./participation-guidelines/participation-guidelines";
@import "./dashboard/dashboard";
