@media only screen and (min-width: 768px) {
  .main {
    .row {
      display: flex;
      // margin-left: 0;
      // margin-right: 0;
    }
    .section {
      .section-title {
        font-size: 46px;
        text-align: left;
      }
    }
    .hero-section {
      .backgrounds {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
      }

      .bg-angled-top {
        position: absolute;
        z-index: -2;
        background-color: $theme-link-water;
        width: 70%;
        height: 100%;
        right: 0;
        bottom: -100%;
        display: block;
        transform: skewY(-28deg);
        transform-origin: 0;
      }

      .bg-angled-bottom {
        position: absolute;
        z-index: 0;
        background-color: white;
        width: 30%;
        height: 100%;
        left: 0;
        bottom: -15.86vw;
        display: block;
        transform: skewY(-28deg);
        transform-origin: 0;
      }

      .section-title {
        margin-bottom: 25px;
        text-align: left;
      }
      .hero-content {
        margin-bottom: 50px;
        text-align: left;
      }
      .home-hero-video {
        height: 170px;
        margin-bottom: 0;
        img {
          margin-bottom: 0;
        }
      }
    }
    .actions {
      text-align: left;
      padding: 0;
      .sign-up {
        background-color: $theme-link-water;
        padding-right: 25px;
        padding-top: 35px;
        padding-bottom: 35px;
        padding-left: 100px;
        justify-content: flex-end;
        margin-right: 35px;
        display: inline-block;

        a {
          padding: 12px 15px;
        }
      }
      .know-more {
        padding: 12px 18px;
        height: 42px;
        display: inline-block;
      }
    }

    .why-join-section {
      .reasons-block {
        display: flex;
        align-items: flex-end;
      }
      .section-title {
        margin-bottom: 60px;
        text-align: right;
      }
      .reasons {
        padding-right: 15px;
        padding-left: 15px;
        margin-bottom: 0 !important;
      }
      .reason {
        &:first-child {
          margin-bottom: 40px;
        }
        .reason-title {
          font-size: 30px;
        }
      }
      .reason-expand {
        height: 270px;

        .reason-count-block {
          margin-top: -110px;
          .reason-count {
            font-size: 220px;
          }
        }
      }
    }

    .event-section {
      .section-title {
        margin-bottom: 60px;
      }
      .events-banner {
        max-width: 300px;
        margin-bottom: 0;
      }

      .event {
        width: 400px;
        margin-left: auto;

        &:first-child {
          margin-bottom: 35px;
        }

        .event-details {
          width: 350px;
        }
      }

      .event-actions {
        justify-content: flex-end;
        margin-top: 10px;
        margin-bottom: 0;
      }
    }

    .subscribe-section {
      .section-title {
        font-size: 40px;
      }
      .subscribe-content {
        flex-direction: row;
      }
    }
  }
}
