@media only screen and (min-width: 1200px) {
  .page-participation-guidelines {
    p,
    ul,
    ol {
      font-size: 18px;
    }
  }
  .section-header {
    margin-top: 45px;
    font-size: 40px;
    margin-bottom: 10px;
  }
}
