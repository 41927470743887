.page-change-password {
  p,
  div,
  input,
  textarea,
  select {
    @extend .font-intel-clear-latin-lite;
  }
  .page-title {
    @extend .font-intel-clear-pro-bold;
    font-size: 44px;
  }
  .login-signup-form .form-group label {
    @extend .font-intel-clear-latin-lite;
  }
}

.page-login {
  p,
  div,
  input,
  textarea,
  select {
    @extend .font-intel-clear-latin-lite;
  }
  .page-title {
    @extend .font-intel-clear-pro-bold;
    font-size: 44px;
  }
  .login-signup-form .form-group label {
    @extend .font-intel-clear-latin-lite;
  }
}

.page-forgot-password {
  p,
  div,
  input,
  textarea,
  select {
    @extend .font-intel-clear-latin-lite;
  }
  .page-title {
    @extend .font-intel-clear-pro-bold;
    font-size: 44px;
  }
  .login-signup-form .form-group label {
    @extend .font-intel-clear-latin-lite;
  }
}
.page-signup {
  p,
  div,
  input,
  textarea,
  select {
    @extend .font-intel-clear-latin-lite;
  }
  .page-title {
    @extend .font-intel-clear-pro-bold;
    font-size: 44px;
  }
  .login-signup-form .form-group label {
    @extend .font-intel-clear-latin-lite;
  }
}

.page-account-verification {
  .page-title {
    @extend .font-intel-clear-pro-bold;
    margin-top: 10%;
    font-size: 44px;
  }
  .page-title + .page-title {
    margin-top: 30px;
    font-size: 40px;
  }
  .page-content {
    @extend .font-intel-clear-latin-lite;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}
