.page-participation-guidelines {
  padding-bottom: 200px;

  p,
  ul,
  ol {
    font-size: 14px;
    line-height: 1.75;
  }

  a {
    @extend .font-intel-clear-latin-bold;
    color: $theme-cello;
    &:hover {
      text-decoration: underline !important;
    }
  }

  .page-header {
    @extend .font-intel-clear-pro-bold;
    font-size: 40px;
  }

  .page-subheader {
    @extend .font-intel-clear-latin-bold;
    margin-bottom: 25px;
  }

  .section-header {
    @extend .font-intel-clear-pro-bold;
    margin-top: 30px;
    font-size: 30px;
    margin-bottom: 5px;
  }

  .section-list {
    list-style: unset;
    padding-left: 30px;
    margin-bottom: 1.25em;
    color: black;
  }
}

@import 'md';
@import 'xl';
