@media only screen and (min-width: 768px) {
  .cbse-logo {
    height: 60px !important;
  }

  .intel-logo {
    height: 40px !important;
  }

  .aisc-logo {
    height: 60px !important;
  }

  .header {
    .header-links-container {
      justify-content: space-between;
      flex-direction: row;
    }
    .header-links {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .header-brand {
      margin-bottom: 0;
    }
  }
}
