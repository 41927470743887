@media only screen and (min-width: 1200px) {
  .footer {
    padding-top: 40px;
    .reach-us-at {
      font-size: 16px;
      margin-bottom: 5px;
    }
    .contact-email {
      font-size: 22px;
      line-height: 1;
      margin-bottom: 8px;
    }
    .social-media-link {
      img {
        width: 30px;
        height: 30px;
      }
      & + .social-media-link {
        margin-left: 25px;
      }
    }
    .footer-links {
      flex-direction: column;
    }
    .footer-link {
      font-size: 18px;

      & + .footer-link {
        margin-top: 0px;
      }
    }
    .footer-logo {
      padding-top: 6px;
      height: 70px;
    }
    .footer-side-link {
      text-decoration: none;
      font-size: 18px;
      font-weight: 400px;
      & + .footer-side-link {
        margin-top: 0px;
      }
    }
    .copyright-text {
      font-size: 12px;
      margin-top: 50px;
    }
  }
}
