@media only screen and (min-width: 768px) {
  .footer {
    text-align: left;

    .social-media-links {
      margin-bottom: 0;
    }
    .footer-side-links-container {
      text-align: right;
    }
    .footer-side-links-container {
      display: flex;

      text-align: left;
    }
    .reach-us {
      padding-right: 100px;
    }
  }
}
