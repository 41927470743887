@media only screen and (min-width: 1440px) {
  .content-container {
    max-width: 1440px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .content-with-bg {
    &.content-bg-left {
      margin-left: -2000px;
      .content-block {
        margin-left: 2000px;
      }
    }

    &.content-bg-right {
      margin-right: calc(-50vw + 720px);
    }
  }
}
